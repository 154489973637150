/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CategoryPageLayout } from '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.config';
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { PRODUCTS_PRELOAD_COUNT } from '@scandipwa/scandipwa/src/util/Product';
import { setLoadedFlag } from '@scandipwa/scandipwa/src/util/Request/LowPriorityLoad';

import Link from 'SourceComponent/Link';
import ProductCard from 'SourceComponent/ProductCard';
import { ProductListPageComponent as SourceProductListPageComponent }
    from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.style';
/* eslint-disable */

/** @namespace TrouperPwa/Component/ProductListPage/Component */

export class ProductListPageComponent extends SourceProductListPageComponent {
    renderPageItems(): ReactElement {
        const {
            items,
            banners,
            selectedFilters,
            mix: { mods: { layout = CategoryPageLayout.GRID } = {} },
        } = this.props;

        let imageIndex = 0;

        return items?.map((product, i) => {

            const isInitialImageSet = i === 2; // Show images after first 3 products
            const isSubsequentImageSet = i > 2 && (i - 2) % 6 === 0; // Show images every 6 products after the first set
            const shouldShowImages = isInitialImageSet || isSubsequentImageSet;
            const imagesToShow = shouldShowImages
                ? banners?.slice(imageIndex, imageIndex + 2)
                : [];

            // Update imageIndex for the next set of images if needed
            if (shouldShowImages) {
                imageIndex += 2;
            }

            if (i < PRODUCTS_PRELOAD_COUNT) {
                return (
                    <>
            <ProductCard
              product={ product }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
              selectedFilters={ selectedFilters }
              layout={ layout as CategoryPageLayout }
              { ...this.containerProps() }
              onLoad={ setLoadedFlag }
            />

            { shouldShowImages && imagesToShow?.length > 0 && (
                <>
                { imagesToShow?.map((val: any, index: number) => (
                  <div block="ProductListPage" elem="BannerWrapper" key={ index }>
                    <Link to={ val?.banner_link || '#' }>
                    <img
                      src={ val?.banner_img }
                      alt={ val.banner_title }
                    />
                    </Link>
                  </div>
                )) }
                </>
            ) }
                    </>
                );
            }

            return (
                <>
          <ProductCard
            product={ product }
            // eslint-disable-next-line react/no-array-index-key
            key={ i }
            selectedFilters={ selectedFilters }
            layout={ layout as CategoryPageLayout }
            { ...this.containerProps() }
            onLoad={ setLoadedFlag }
          />
          { shouldShowImages && imagesToShow?.length > 0 && (
              <>
              { imagesToShow?.map((val: any, index: number) => (
                <div block="ProductListPage" elem="BannerWrapper" key={ index }>
                  <Link to={ val?.banner_link || '#' }>
                  <img
                    src={ val?.banner_img }
                    alt={ val.banner_title }
                  />
                  </Link>
                </div>
              )) }
              </>
          ) }
                </>
            );
        });
    }
}

export default ProductListPageComponent;
